.containerAlertIngresos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textAlertIngresos{
    text-align: center;
    margin-bottom: 40px;
}
.alertIcon{
    filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
}

.mt-20{
    margin-top: 20px!important;
}