body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background: #fff;
}
*{
  font-family: 'Montserrat', sans-serif!important;
}

code {
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.App{
  width: 100%;
  height: 100vh;
  background: #fff;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0D1F3D;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #0D1F3D;
  --rdp-background-color-dark: #0D1F3D;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;  
  padding: 0 5% 0 5%;
  background: linear-gradient(to bottom, #162845, #0a5ea4);
  height: 150px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
.nav{
  margin-left: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.btn-primary{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #10192C;
  color: #f1f1f1ef;
  border: none;
  border-radius: 25px;
  width: 110px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding-right: 10px;
}
.backIcon{
  margin-right: 10px;
  /* margin-left: 10px; */
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  background-color: #090E19;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.step{
  width: 100%;
  display: flex;
  align-items: center;
}
.step button{
  height: 30px;
  width: 30px;
  padding: 0;
  border-radius: 50%;
  border: none;
  margin: 0 5px 0 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  /* color: #558EA8;
  background: #C5D6EF; */
}
.connector{
  width: 60%;
  margin: auto;
  height: 2px;
  color: transparent;
}
.titleHeader{
  text-decoration: none;
  color: #fff;
}

.layout{
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px 10% 0 10%; */
}

.cardStep{
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  height: auto;
}
.cardStepBody{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.titleStepContainer{
  width: 52vw;
}
.titleStepContainer h1{
  font-size: 20px;
  font-weight: 700;
  color: #0D1F3D;
  margin: 0;
}
.titleStepContainer p{
  font-size: 18px;
  font-weight: 400;
  color: #585858;
  margin: 0 0 10px 0;
}

.button{
  height: 35px!important;
  width: 80%!important;
  margin: 20px 0 20px 0!important;
  font-size: 20px;
  background-color: #0D1F3D;
  border-radius: 8px!important;
  color: white!important;
  cursor: pointer!important;
  border: none!important;
}

.continueBtn{
  width: 150px;
  height: 40px;
  background-color: #338dc7;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}
.BackBtn{
  width: 100px;
  height: 40px;
  margin-right: 10px;
}

.input{
  width: 250px;
  height: 40px;
  border-radius: 7px;
  border: none;
  text-indent: 5px;
  background-color: #2a5272;
  color: #fff;
}

.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px!important;
}

.labelOption{
  color: #444444;
  font-size: 13px;
  width: auto!important;
  font-weight: 300;
}

.labelOptionBold{
  margin-top: 20px;
  color: #656565;
  font-weight: 600;
  font-size: 14px;
}

.bold{
  font-weight: 700;
  font-size: 18px;
}
.dateSelect{
  margin-top: 5px;
  width: 250px;
  height: 40px;
  border-radius: 7px;
  text-indent: 5px;
  background-color: #2a5272;
  color: #fff;
  border: none;
  text-transform: uppercase;
}
.dateSelect::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1); /* Invertir el color del icono a blanco */
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px); /* Mover 10px hacia la izquierda */
}
.logoContainer{
  /* background-color: #fff; */
  border-radius: 20px;
  margin: 5px 0 5px 0;
  margin-top: 50px;
}
.logoContainer img{
  width: 200px;
  cursor: pointer;
}
.row{
  display: flex;
}

.radioInput{
  -webkit-appearance: none;
          appearance: none;
  height: 15px;
  width: 15px;
  background: #CACACA; /* Cambia esto al color que desees */
  border-radius: 50%;
  /* Establece un color de borde personalizado */
  border: 1px #325775 solid; /* Cambia esto al color que desees */
  /* Define el color del marcador (punto en el medio del botón) */
  /*outline-color: #ffffff; /* Cambia esto al color que desees */
}

/* Establece el color de fondo del botón de radio cuando está marcado */
.radioInput:checked {
  background: #637a8f; /* Cambia esto al color que desees */
  outline-color: #ffffff; /* Cambia esto al color que desees */
}

.tableHead{
  background-color: #204D72;
  height: 40px!important;
}

.textTableHead{
  padding: 0!important;
  text-align: center!important;
  width: 200px!important;
}

.tableCellBody{
  align-items: center!important;
  height: 25px!important;
  background: #fff!important;
  color: #606060!important;
  font-size: 13px!important;
  padding: 0!important;
  text-align: center!important;
  width: 200px!important;
}
.deleteContainerTable{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.blue1{
  background-color: #4B657F!important;
}
.blue2{
  background-color: #45576D!important;
}
.blue3{
  background-color: #204D72!important;
}
.blue4{
  background-color: #213D4F!important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* Puedes agregar reglas adicionales para otros navegadores, si es necesario */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.blueBTN{
  background-color: #19476c;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  min-width: 150px;
  height: 40px;
}

.alertIcon{
  height: 100px;
}

.navBtnsContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-content{
  border-radius: 7px!important;
}

.flex{
  display: flex;
}
.ml-20{
  margin-left: 20px;
}
.fullWidth{
  width: 100%;
}

.LoginRegisterLayout{
  display: flex;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.personIconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #0D1F3D;
  cursor: pointer;
}



.messageContainer{
  position: absolute;
  right: 6%;
  top: 100px;
  width: 150px;
  padding: 10px;
  border-radius: 7px;
  border-top-right-radius: 0px;
  background: #E4EEEF;
  box-shadow: #090E19 2px 3px 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.messageContainer p{
  font-size: 12px;
  font-weight: 500;
  color: #656565;
  text-align: center;
}

.messageContainer button{
  border: none;
  background: #2D8AC3;
  border-radius: 7px;
  font-size: 400;
  color: #fff;
  font-size: 12px;
  height: 25px;
  cursor: pointer;
}

.loginButtonReset{
  height: 40px;
  width: 150px;
  color: #0463A2;
  background: #E7E9ED;
  font-size: 14px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
.dateBtn{
    margin-bottom: 100px;
}
.titleStepContainerDate h1{
    font-size: 22px;
    font-weight: 700;
    color: #0D1F3D;
    margin: 0 0 20px 0;
    text-align: center;
}
.cardStepBodyDate{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #e5e5e5;
    border-radius: 7px;
    width: auto;
    padding: 10px 20px 30px 30px;
}

.smallWidth{
    width: 80%;
}
.smallWidth2{
    width: 400px;
}
.mt{
    margin-top: 10px;
}
.continueContainerDate{
    width: 100%;
    display: flex;
    justify-content: center;
}

.alertContainerDate{
    background-color: transparent;
    flex-direction: column;
    align-items: center;
}

.AlertHeaderContainerDate{
    display: flex;
    justify-content: center;
    align-items: center;
}

.AlertHeaderContainerDate{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AlertHeaderContainerDate img{
    height: 100px;
    -webkit-filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
            filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
}
.AlertHeaderContainerDate h1{
    color: #d3e7ff;
    font-size: 24px;

}

.AlertTextDate{
    font-size: 14px;
    font-weight: 300;
    color: #d3e7ff; 
    width: 100%;
    text-align: center;   
}
.continueButtonContainer{
    position: fixed;
    margin-top: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.revisionDateContainer{
    margin-top: 6%;
}

.bannerContainer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.bannerContainer img{
    width: 80%;
}

.erroDateContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.erroDateContainer img{
    height: 100px;
}
.erroDateContainer h1{
    color: #606060;
    font-size: 24px;
    font-weight: 600;
}

.erroDateContainer p{
    color: #606060;
    text-align: center;
    margin: 20px 50px 20px 50px;
}
.infoInput{
    margin-top: 5px;
}

.selectInput{
    width: 255px;
    height: 40px;
    border-radius: 8px;
    margin-top: 5px;
    border: solid 1px #0D1F3D;
}

.userInfoContainer{
    width: 100%;
    margin: 0 5%;
}
.titleHeaderUser h1{
    color: #18243d;
    width: 100%;
    font-weight: 600;
}

.cardUserInfo{
    margin-top: 20px;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #e5e5e5;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}
.cardUserInfoLeft{
    margin-top: 20px;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
}
.inputLabelContainer{
    display: flex;
    flex-direction: column;
}
.radiosContainer{
    display: flex;
    height: 40px;
}

.radioContainer{
    display: flex;
    align-items: center;
}
.widthUser{
    width: 200px!important;
}
.buttonIngreso{
    height: 40px;
    width: 250px;
    border: none;
    border-radius: 8px;
    background-color: #2d88c1;
    color: #fff;
    margin-top: 5px;
    cursor: pointer;
}
.grid{
    margin-left: 7%;
}
.muerteAlert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
}
.sufrioTExt{
    margin: 0 40px 20px 40px;
}

.usuarioSpace{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 10%;
}


.mt-20{
    margin-top: 20px!important;
}
.modalContainer{
    width: 500px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
}
.addBtn{
    width: 100%;
}
.label{
    font-weight: 700;
    margin-top: 5px;
}
.subLabel{
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.unico{
    font-size: 16px;
    margin: 5px 0 15px 0;
}
.unicoContainer{
    margin: 5px 0 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkBox{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-left: 15px;
}
.insertHeader{

}
.insertHeader h1{
    color: #606060;
    font-size: 20px;
}
.mt{
    margin-top: 10px;
}
.widthInsert{
    width: 80%;
}

.addContainerInsert{
    display: flex;
    justify-content: flex-end;
}
.buttonInsert{
    width: 100px;
    height: 40px;
    background-color: #328cc6;
    border-radius: 8px;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}
.specialInput{
    height: 30px;
    margin-left: 10px;
}
.datesContainerInsert{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.dateContainerInsert{
    display: flex;
    flex-direction: column;
}
.insertWidth{
    width: 220px!important;
}
.stepsContainer{
    display: flex;
    flex-direction: column;
}

.stepsContainer h1{
    color: #606060;
    font-size: 20px;
    font-weight: 600;
}
.containerSelectIngresosModal{
    position: absolute;
    background-color: #0d78bc;
    border-radius: 7px;
    width: 80vw;
}
.selectIngresosContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.tableSelect{
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-bottom: 40px;
}
.editSelectBtn{
    border: none;
    background-color: #0d78bc;
    height: 35px;
    width: 120px;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    border-radius: 7px;
    cursor: pointer;
}

.yesOrno{
    display: flex!important;
}
.yesOrnoBTN{
    height: 40px;
    width: 45px;
    border-radius: 7px;
    background-color: #0d78bc;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    font-weight: 300;
    margin-top: 20px!important;
}

.align-center{
    display: flex;
    align-items: center;
}

.margin-top-20{
    margin-top: 20px;
}
.margin-top-10{
    margin-top: 20px;
}

.radios{
    display: flex;
    align-items: center;
    border-left: #0E4D87 2px solid;
    background: #E9E9E9;
    height: 40px;
    padding-right: 10px;
    margin-left: 10px;
}

.continuarImpactoVida{
    height: 40px;
    width: 150px;
    border: none;
    border-radius: 7px;
    background-color: #0d78bc;
    font-size: 13px;font-weight: 300;
    color: white;
    position: absolute;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
}
.mt-20{
    margin-top: 20px;
}

.column{
    display: flex;
    flex-direction: column;
}

.mt-20{
    margin-top: 20px!important;
}
.alertCapacidadContainer p{
    font-size: 13px;
    font-weight: 300;
    color: #fff;
}

.alertCapacidadContainer img{
    height: 100px;
    -webkit-filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
            filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
}

.alertCapacidadContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.alertCapacidadContainer button{
    height: 35px!important;
    width: 150px;
    font-size: 13px;
    margin-top: 20px;
}

.mt-20{
    margin-top: 20px!important;
}
.preguntaContainer{
    background-color: #6B9DB3;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px;
    padding: 40px;
}
.textTableHead{
    color: #fff!important;
}
.tablePregunta{
    margin-top: 40px!important;
}

.titlePregunta{
    font-weight: 300;
}
.titlePregunta b{
    font-weight: 700;
}

.preguntaHeader{
    display: flex;
    align-items: center;
}

.buttonsContainer{
    margin-left: 20px;
}

.buttonsContainer button {
    height: 35px;
    width: 40px;
    background: #fff;
    color: #6B9DB3;
    border: none;
    border-radius: 7px;
    margin-right: 10px;
    font-weight: 300;
    cursor: pointer;
}

.alertPreguntaContainer{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alertPreguntaContainer h1{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
}

.mt-20{
    margin-top: 20px!important;
}
.containerAlertIngresos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textAlertIngresos{
    text-align: center;
    margin-bottom: 40px;
}
.alertIcon{
    -webkit-filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
            filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
}

.mt-20{
    margin-top: 20px!important;
}
.gestorIngresosHeader{
    display: flex;
    flex-direction: column;
}

.gestorIngresosContainer{
    background-color: #E5E5E5;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px;
    padding: 40px;
}

.deleteCell{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.footerGestor{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.blueGestor{
    background-color: #1A476C!important;
}

.mt-20{
    margin-top: 20px!important;
}
.modalContainer{
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
}
.addBtn{
    width: 100%;
}
.label{
    font-weight: 700;
    margin-top: 5px;
}
.subLabel{
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.unico{
    font-size: 16px;
    margin: 5px 0 15px 0;
}
.unicoContainer{
    margin: 5px 0 5px 0;
    display: flex;
    align-items: center;
}
.checkBox{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-left: 15px;
}

.mt-20{
    margin-top: 20px!important;
}
.cardTable{
    margin-top: 20px;
    border: #666666 solid 0.5px;
    border-radius: 8px;
    padding: 10px;
}

.headerCardTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #666666 solid 0.5px;
}
.headerCardTable h2{
    color: #0D1F3D;
    font-weight: 700;
    font-size: 16px;
}
.table{
    margin-top: 10px;
}
.titleTable{
    font-weight: 700;
    font-size: 15px;
    color: #0D1F3D;
}
.insertButtonDaño{
    border-radius: 5px;
    color: #fff;
    border: none;
    height: 40px;
    background: #3171C8;
    cursor: pointer;
    width: 180px;
    font-size: 12px;
    font-weight: 300;
    margin-top: 20px;
}
.priceHeader{
    height: 60px;
    margin-bottom: 10px;
}

.priceTitle{
    color: #0D1F3D;
    font-size: 14px;
    font-weight: 700;
}

.price{
    color: #3171C8;
    font-size: 14px;
    font-weight: 700;
}

.pricesContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 130px;
}

.cardPrice{
    border: #666666 solid 0.5px;
    border-radius: 8px;
    padding: 10px;
    width: 45%;
}
.total{
    border: none;
    background: #3171C8;
}
.total .priceTitle{
    color: #fff;
}
.total .price{
    color: #D8D95D;
}

.enviarBtn{
    height: 40px;
    width: 300px;
    border-radius: 5px;
    background: #0D1F3D;
    color: #fff;
    font-size: 16px;
}
.enviarBtnContainer{
    display: flex;
    justify-content: right;
}

.dañoEmergenteContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
}
.dañoEmergenteHeader h1{
    font-weight: 600;
    font-size: 22px;
    color: #17233C;
}
.widthDaño{
    width: 50%!important;
    display: flex;
}

.dañoCard{
    display: flex;
    align-items: center;
    border-radius: 7px;
    background-color: #E5E5E5;
    padding: 20px 0 20px 0;
    margin-top: 20px
}

.dañoColumn{
    display: flex;
    flex-direction: column;
    margin: 5%;
}

.noMarginTop{
    margin-top: 0!important;
}

.rowTable{
    display: flex;
    flex-direction: column;
}

.mt-20{
    margin-top: 20px!important;
}
.cardContainerTable{
    background-color: #E5E5E5;
    border-radius: 7px;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}
.mt-20{
    margin-top: 20px!important;
}
.cardContainerTable{
    background-color: #E5E5E5;
    border-radius: 7px;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.editBtnsDaño{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.mt-20{
    margin-top: 20px!important;
}
.container{
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 0;
}
.link{
    width: 100%;
    text-align: center;
}
.card{
    width: 30%;
    height: auto;
    margin-top: 30px;
    padding: 10px;
    background: #fff;
    color: #0D1F3D;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.card p{
    width: 80%;
    text-align: center;
    color: #585858;
}
/* .dlaLogo{
    background-color: #fff;
    border-radius: 10px;
} */

.dlaLogo{
    /* margin-top: 100px; */
}
.dlaLogo img{
    height: 150px;
}

.link{
    font-size: 12px;
    text-decoration: none;
    color: #0e1f3d;
    margin-left: 10px;
}

.checkContainer{
    display: flex;
    align-items: center;
}

.checkbox{
    margin: 0;
}

.welcomeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
h1{
    font-weight: 600;
    font-size: 25px;
}

.welcomeContainer p{
    width: 50%;
    text-align: center;
    font-weight: 300;
    color: #ffffff;
    font-size: 14px;
}

.button-local{
    width: 250px;
    color: #616161!important;
    font-weight: 300!important;
    margin-top: 40px!important;
    border-radius: 7px!important;
}

.mt-20{
    margin-top: 20px!important;
}
.alertContainer{
    /* width: 700px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AlertHeaderContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.AlertHeaderContainer img{
    height: 100px;
}
.AlertHeaderContainer h1{
    color: #686868;
    font-size: 24px;

}
.AlertText{
    font-size: 14px;
    font-weight: 300;
    color: #585858; 
    width: 90%;
    text-align: center;   
}
.alertSubText{
    font-size: 12px;
    color: #585858; 
    text-align: center;
    font-weight: 700;
}

.AlertSubTitle{
    margin-top: 20px;
    height: 40px;
    width: 80%;
    background-color: #E14D6C;
    border-radius: 7px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
}
.alertLink{
    margin-left: 5px;
    color: #fff;
}

.alertContinueButton{
    width: 200px!important;
    margin: 20px 0 20px 0!important;
    color: #fff!important;
    background-color: #00619f!important;

}

.cardTable{
    margin-top: 20px;
    border: #666666 solid 0.5px;
    border-radius: 8px;
    padding: 10px;
}

.headerCardTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #666666 solid 0.5px;
}
.headerCardTable h2{
    color: #0D1F3D;
    font-weight: 700;
    font-size: 16px;
}
.table{
    margin-top: 10px;
}
.titleTable{
    font-weight: 700;
    font-size: 15px;
    color: #0D1F3D;
}
.insertButton{
    border-radius: 5px;
    color: #fff;
    border: none;
    height: 25px;
    background: #3171C8;
    cursor: pointer;
}
.priceHeader{
    height: 60px;
    margin-bottom: 10px;
}

.priceTitle{
    color: #0D1F3D;
    font-size: 14px;
    font-weight: 700;
}

.price{
    color: #3171C8;
    font-size: 14px;
    font-weight: 700;
}

.pricesContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 130px;
}

.cardPrice{
    border: #666666 solid 0.5px;
    border-radius: 8px;
    padding: 10px;
    width: 45%;
}
.total{
    border: none;
    background: #3171C8;
}
.total .priceTitle{
    color: #fff;
}
.total .price{
    color: #D8D95D;
}

.enviarBtn{
    height: 40px;
    width: 300px;
    border-radius: 5px;
    background: #0D1F3D;
    color: #fff;
    font-size: 16px;
}
.enviarBtnContainer{
    display: flex;
    justify-content: right;
}
.marginTop{
    margin-top: 5px;
}
.marginTop2{
    margin-top: 15px;
}
.addBtn{
    width: 100%;
}
.column{
    display: flex;
    flex-direction: column;
}
.cardLucroCesante{
    display: flex;
    
}

.mt-20{
    margin-top: 20px!important;
}
.modalContainer{
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
}
.addBtn{
    width: 100%;
}
.label{
    font-weight: 700;
    margin-top: 5px;
}
.subLabel{
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.unico{
    font-size: 16px;
    margin: 5px 0 15px 0;
}
.unicoContainer{
    margin: 5px 0 5px 0;
    display: flex;
    align-items: center;
}
.checkBox{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-left: 15px;
}

.cellCustomWidth{
    width: 30px!important;
}

.WhiteBtnYesOrNo{
    background: #fff;
    border: none;
    height: 40px;
    width: 40px;
    color: #0D78BC;
    border-radius: 7px;
    margin: 5px;
    cursor: pointer;
}

.mt-20{
    margin-top: 20px!important;
}
.ButtonWhatsappContainer{
    position: fixed;
    right: 0px;
    bottom: 20px;
    background: #81A4D7;
    /* border-radius: 25px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 60px;
    box-shadow: #000 0px 0px 1px;
    transition: all 1s ease-in-out;
}
.WhatsappButton{
    height: 50px;
    margin: 0 5px 0 5px;
}

.textContainerWpp{
    width: 180px;
    background: #6081A8;
    height: 60px;
    padding: 0 10px 0 10px;
    display: flex;
    align-items: center;
}

.textContainerWpp p{
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    width: auto;
    text-align: center;
}
.reclamanteContainer{
    width: 100%;
    margin: 0 5%;
}

.cardContainerReclamante{
    width: 100%;
    display: flex;
    background-color: #E5E5E5;
    border-radius: 7px;
    /* justify-content: center;
    margin-top: 5%; */
}
.titleContainer{
    margin-left: 30px;
}
.subContainerReclamante{
    margin: 5% 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.space-betwen{
    display: flex;
    justify-content: space-between;
}
.mr-reclamante{
    margin: 0 5%;
}
.ml-reclamante{
    margin-left: 10px;
}

.mt-reclamante{
    margin-top: 20px;
}

.p-reclamante{
    color: gray;
    font-weight: 300;
    font-size: 13px;
    width: 90%;
}

.align-center{
    align-items: center;
}

.table{
    background-color: #2A5272;
    border-radius: 7px;
    padding: 5%;
    margin-bottom: 20px;
}

.añadirVictima{
    width: 100%;
    margin-top: 20px;

}
.añadirVictimaBtn{
    border: none;
    background-color: #338DC7;
    border-radius: 7px;
    height: 35px;
    width: auto;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px 0 10px;
    position: absolute;
    right: 10%;
}
.modalHeader h2{
    color: #656565;
    font-weight: 600;
    font-size: 20px;
}

.mt-modalInsert{
    margin-top: 20px;
}

.dateContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.widthParcial{
    width: 200px;
}
.nextBTN{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.nextBTN button{
    border: none;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 500;
    background: #328DC6;
    height: 35px;
    color: #fff;
    padding: 0 10px 0 10px;
    cursor: pointer;
}

.labelModalReclamante{
    margin-left: 10px;
}
.noAplicaContainer{
    margin-top: 20px;
    border-left: #2A5272 2px solid;
    width: 100px;
    height: 35px;
    padding: 0 5px 0 5px;
    background: #E9E9E9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mt-20{
    margin-top: 20px!important;
}
.text{
    color: black;
}
.containerPoliticas{
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textContainer{
    background-color: #eaebec;
    width: 50%;
    border-radius: 7px;
    padding: 30px 60px 30px 60px;
}

.textContainer h1{
    color: #606060;
    font-size: 22px;
    text-align: center;
}
.textContainer p{
    color: #606060;
    font-size: 14px;
    text-align: justify;
}
.lucroCesanteContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.cardPerjuicios{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #E5E5E5;
    border-radius: 7px;
}

.subContainerPerjuicios{
    margin: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mt-20{
    margin-top: 20px!important;
}
.tablePregunta{
    width: 80%!important;
    margin: auto!important;
    margin-bottom: 40px!important;
}

.continueBtnContainer{
    width: 80%!important;
    margin: auto;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
}

.mt-20{
    margin-top: 20px!important;
}
.btnsContainer{
    display: flex;
    justify-content: flex-start;
}

.btnsContainer button{
    margin: 10px;
    width: 45px;
    height: 40px;
    color: #fff;
    background: #0071B9;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

.miniInput{
    border: none;
    width: 50px;
   border-bottom: 1px solid #2B5373;
   background: #C0C8CF; 

}

.backgroundVictimasIndirectas{
    border-left: #2B5373 4px solid;
    background-color: #E9E9E9;
    flex-wrap: wrap;
}

.radiosContainerVictimas{
    display: flex;
}

.mt-20{
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 0;
}
.mt-20{
    margin-top: 20px!important;
}
.tablePregunta{
    width: 80%!important;
    margin: auto!important;
    margin-bottom: 40px!important;
}

.continueBtnContainer{
    width: 80%!important;
    margin: auto;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
}

.mt-20{
    margin-top: 20px!important;
}
.tablePregunta{
    width: 80%!important;
    margin: auto!important;
    margin-bottom: 40px!important;
}

.continueBtnContainer{
    width: 80%!important;
    margin: auto;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
}

.mt-20{
    margin-top: 20px!important;
}
.liquidationCOntainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.liquidationCOntainer h1{
    font-size: 22px;
    font-weight: 600;
    color: #17233C;
    margin: 20px;
}

.subTitleLiquidation{
    font-weight: 600;
    font-size: 16px;
    color: #17233C;
    text-decoration: underline;
}

.subLabelLiquidation{
    font-weight: 600;
    font-size: 13px;
    color: #17233C;
    list-style: inside;
    list-style-type: circle;
    display: list-item;
}

.subTitleLiquidation2{
    font-weight: 600;
    font-size: 18px;
    color: #17233C;
}

.subTextLiquidation{
    font-weight: 400;
    font-size: 13px;
    color: #17233C;
    margin-left: 5px;
}

.liquidationSubCOntainer{
    background: #ECF3F4;
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 40px;
}

.dataUser{
    display: flex;
    flex-direction: column;
}

.valuesContainer{
    display: flex;
    margin-top: 20px;
}

.reclamanteContainerLiquidation{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

}
.reclamanteLiquidation{
    margin-top: 20px;
    width: 45%;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
    align-items: flex-start;
}
.mt-20{
    margin-top: 20px;
}

.totalContainer{
    background: #CBDDE3;
    width: 100%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 40px;
    margin-bottom: 40px;
}

.totalFinal{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    background: #598FA7;
    width: 45%;
    height: 70px;
}

.totalFinal2{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    background: #598FA7;
    width: 45%;
    height: 70px;
}

.totalFinal2 h1{
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
}

.totalFinal h1{
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    display: flex;
    align-items: center;
}

.totalText{
    font-size: 20px;
}
.totalText2{
    font-size: 20px;
    margin-right: 10px;
}

.footer-container{
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    justify-content: center;
}

.btn-primary-liquidation{
    height: 40px;
    background: #2C5474;
    color: #fff;
    font-size: 12px;
    width: 140px;
    margin: 0 10px 0 10px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}
.initialWelcomeContainer{
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    height: 100%;
    width: 50%;
    justify-content: left;
    align-items: center;
}

.initialWelcomeTextContainer{
    display: flex;
    flex-direction: column;
}

.InitialWelcomelogoContainer img{
    margin: 30% 0 0 10%;
    width: 300px;
}

.initialWelcomeTextContainer h1 {
    margin: 0 0 0 10%;
    font-size: 25px;
    font-weight: 600;
}
.initialWelcomeTextContainer p {
    margin: 20px 0 0 10%;
    font-size: 14px;
    font-weight: 400;
    width: 75%;
}

.InitialWelcomeseparator{
    color: transparent;
    height: 2px;
    width: 25px;
    background: #fff;
    margin: 20px 0 25px 10%;
}
.LoginFormContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

.loginForm{
    background: #EBF2F3;
    border-radius: 7px;
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
}

.loginFormTitle{
    color: #455064;
    font-size: 24px;
    font-weight: 600;
    margin: 10% 0 0 10%;
}

.loginFormMargin{
    margin: 3% 0 0 10%;
}

.loginFormInput{
    width: 78%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #455064;
    background: #fff;
    text-indent: 10px;
}

.loginFormButtonContainer{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginFormButton{
    height: 40px;
    width: 200px;
    background: #436783;
    border: none;
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.loginFormLink{
    font-size: 12px;
    text-decoration: none;
    color: #2F8AC4;
    margin-top: 10px;
}
.loginFormButtonCreate{
    margin: auto;
    margin-top: 30px;
    height: 40px;
    width: 160px;
    background: #2F8AC4;
    border: none;
    border-radius: 7px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}
.politicsText{
    color: #606060;
    font-style: italic;
    font-size: 16px;
}

.linkRegisterPolitics{
    text-decoration: underline;
    cursor: pointer;
}

.registerPolitics{
    margin-top: 20px;
    margin-left: 10%;
}


.resetContainer{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetContainerForm{
    background: #EBF2F3;
    border-radius: 7px;
    height: 300px;
    width: 500px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.sendButton{
    height: 40px;
    width: 100px;
    background: #2F8AC4;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.emailReset{
    width: 90%;
    border: 0.3px solid #20628b;
    border-radius: 7px;
    height: 40px;
    margin-bottom: 25px;
    text-indent: 10px;
}

.resetPasswordTitle{
    font-size: 26px;
    font-weight: 600;
    color: #17233C;
    margin-bottom: 20px;
}

.subTextResetPassword{
    font-size: 14px;
    font-weight: 400;
    color: gray;
    margin-bottom: 20px;
    width: 90%;
}
.creditsPage{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.creditsLogo{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.creditsLogo img {
    width: 400px;
}

.textCreditsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CreditsPrincipalTitle{
    font-size: 24px;
    font-weight: 600;
    margin-top: 40px;
}

.subTextPrincipalCredits{
    font-weight: 400;
    font-size: 14px;
    width: 60%;
    text-align: center;
}

.CreditsTitle{
    font-size: 16px;
    margin-bottom: 0;
}
.subContainerText{
    display: flex;
}

.credistText{
    margin-top: 11px;
    margin-left: 10px;
}

.btn-credits{
    width: 250px;
    height: 45px;
    background: #D2E1EC;
    color: #152B4A;
    font-size: 12px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

.creditsButtonsContainer{
    margin-top: 60px;
    width: 27%;
    display: flex;
    justify-content: space-between;
}
