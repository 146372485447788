.creditsPage{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.creditsLogo{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.creditsLogo img {
    width: 400px;
}

.textCreditsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CreditsPrincipalTitle{
    font-size: 24px;
    font-weight: 600;
    margin-top: 40px;
}

.subTextPrincipalCredits{
    font-weight: 400;
    font-size: 14px;
    width: 60%;
    text-align: center;
}

.CreditsTitle{
    font-size: 16px;
    margin-bottom: 0;
}
.subContainerText{
    display: flex;
}

.credistText{
    margin-top: 11px;
    margin-left: 10px;
}

.btn-credits{
    width: 250px;
    height: 45px;
    background: #D2E1EC;
    color: #152B4A;
    font-size: 12px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

.creditsButtonsContainer{
    margin-top: 60px;
    width: 27%;
    display: flex;
    justify-content: space-between;
}