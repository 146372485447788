.cardContainerTable{
    background-color: #E5E5E5;
    border-radius: 7px;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.editBtnsDaño{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.mt-20{
    margin-top: 20px!important;
}