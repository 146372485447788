.dateBtn{
    margin-bottom: 100px;
}
.titleStepContainerDate h1{
    font-size: 22px;
    font-weight: 700;
    color: #0D1F3D;
    margin: 0 0 20px 0;
    text-align: center;
}
.cardStepBodyDate{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #e5e5e5;
    border-radius: 7px;
    width: auto;
    padding: 10px 20px 30px 30px;
}

.smallWidth{
    width: 80%;
}
.smallWidth2{
    width: 400px;
}
.mt{
    margin-top: 10px;
}
.continueContainerDate{
    width: 100%;
    display: flex;
    justify-content: center;
}

.alertContainerDate{
    background-color: transparent;
    flex-direction: column;
    align-items: center;
}

.AlertHeaderContainerDate{
    display: flex;
    justify-content: center;
    align-items: center;
}

.AlertHeaderContainerDate{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AlertHeaderContainerDate img{
    height: 100px;
    filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
}
.AlertHeaderContainerDate h1{
    color: #d3e7ff;
    font-size: 24px;

}

.AlertTextDate{
    font-size: 14px;
    font-weight: 300;
    color: #d3e7ff; 
    width: 100%;
    text-align: center;   
}
.continueButtonContainer{
    position: fixed;
    margin-top: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.revisionDateContainer{
    margin-top: 6%;
}

.bannerContainer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.bannerContainer img{
    width: 80%;
}