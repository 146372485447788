.liquidationCOntainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.liquidationCOntainer h1{
    font-size: 22px;
    font-weight: 600;
    color: #17233C;
    margin: 20px;
}

.subTitleLiquidation{
    font-weight: 600;
    font-size: 16px;
    color: #17233C;
    text-decoration: underline;
}

.subLabelLiquidation{
    font-weight: 600;
    font-size: 13px;
    color: #17233C;
    list-style: inside;
    list-style-type: circle;
    display: list-item;
}

.subTitleLiquidation2{
    font-weight: 600;
    font-size: 18px;
    color: #17233C;
}

.subTextLiquidation{
    font-weight: 400;
    font-size: 13px;
    color: #17233C;
    margin-left: 5px;
}

.liquidationSubCOntainer{
    background: #ECF3F4;
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 40px;
}

.dataUser{
    display: flex;
    flex-direction: column;
}

.valuesContainer{
    display: flex;
    margin-top: 20px;
}

.reclamanteContainerLiquidation{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

}
.reclamanteLiquidation{
    margin-top: 20px;
    width: 45%;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
    align-items: flex-start;
}
.mt-20{
    margin-top: 20px;
}

.totalContainer{
    background: #CBDDE3;
    width: 100%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 40px;
    margin-bottom: 40px;
}

.totalFinal{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    background: #598FA7;
    width: 45%;
    height: 70px;
}

.totalFinal2{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    background: #598FA7;
    width: 45%;
    height: 70px;
}

.totalFinal2 h1{
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
}

.totalFinal h1{
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    display: flex;
    align-items: center;
}

.totalText{
    font-size: 20px;
}
.totalText2{
    font-size: 20px;
    margin-right: 10px;
}

.footer-container{
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    justify-content: center;
}

.btn-primary-liquidation{
    height: 40px;
    background: #2C5474;
    color: #fff;
    font-size: 12px;
    width: 140px;
    margin: 0 10px 0 10px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}