.cardTable{
    margin-top: 20px;
    border: #666666 solid 0.5px;
    border-radius: 8px;
    padding: 10px;
}

.headerCardTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #666666 solid 0.5px;
}
.headerCardTable h2{
    color: #0D1F3D;
    font-weight: 700;
    font-size: 16px;
}
.table{
    margin-top: 10px;
}
.titleTable{
    font-weight: 700;
    font-size: 15px;
    color: #0D1F3D;
}
.insertButtonDaño{
    border-radius: 5px;
    color: #fff;
    border: none;
    height: 40px;
    background: #3171C8;
    cursor: pointer;
    width: 180px;
    font-size: 12px;
    font-weight: 300;
    margin-top: 20px;
}
.priceHeader{
    height: 60px;
    margin-bottom: 10px;
}

.priceTitle{
    color: #0D1F3D;
    font-size: 14px;
    font-weight: 700;
}

.price{
    color: #3171C8;
    font-size: 14px;
    font-weight: 700;
}

.pricesContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 130px;
}

.cardPrice{
    border: #666666 solid 0.5px;
    border-radius: 8px;
    padding: 10px;
    width: 45%;
}
.total{
    border: none;
    background: #3171C8;
}
.total .priceTitle{
    color: #fff;
}
.total .price{
    color: #D8D95D;
}

.enviarBtn{
    height: 40px;
    width: 300px;
    border-radius: 5px;
    background: #0D1F3D;
    color: #fff;
    font-size: 16px;
}
.enviarBtnContainer{
    display: flex;
    justify-content: right;
}

.dañoEmergenteContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
}
.dañoEmergenteHeader h1{
    font-weight: 600;
    font-size: 22px;
    color: #17233C;
}
.widthDaño{
    width: 50%!important;
    display: flex;
}

.dañoCard{
    display: flex;
    align-items: center;
    border-radius: 7px;
    background-color: #E5E5E5;
    padding: 20px 0 20px 0;
    margin-top: 20px
}

.dañoColumn{
    display: flex;
    flex-direction: column;
    margin: 5%;
}

.noMarginTop{
    margin-top: 0!important;
}

.rowTable{
    display: flex;
    flex-direction: column;
}

.mt-20{
    margin-top: 20px!important;
}