.ButtonWhatsappContainer{
    position: fixed;
    right: 0px;
    bottom: 20px;
    background: #81A4D7;
    /* border-radius: 25px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 60px;
    box-shadow: #000 0px 0px 1px;
    transition: all 1s ease-in-out;
}
.WhatsappButton{
    height: 50px;
    margin: 0 5px 0 5px;
}

.textContainerWpp{
    width: 180px;
    background: #6081A8;
    height: 60px;
    padding: 0 10px 0 10px;
    display: flex;
    align-items: center;
}

.textContainerWpp p{
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    width: auto;
    text-align: center;
}