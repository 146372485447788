.gestorIngresosHeader{
    display: flex;
    flex-direction: column;
}

.gestorIngresosContainer{
    background-color: #E5E5E5;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px;
    padding: 40px;
}

.deleteCell{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.footerGestor{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.blueGestor{
    background-color: #1A476C!important;
}

.mt-20{
    margin-top: 20px!important;
}