.lucroCesanteContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.cardPerjuicios{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #E5E5E5;
    border-radius: 7px;
}

.subContainerPerjuicios{
    margin: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mt-20{
    margin-top: 20px!important;
}