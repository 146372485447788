.modalContainer{
    width: 500px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
}
.addBtn{
    width: 100%;
}
.label{
    font-weight: 700;
    margin-top: 5px;
}
.subLabel{
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.unico{
    font-size: 16px;
    margin: 5px 0 15px 0;
}
.unicoContainer{
    margin: 5px 0 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkBox{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-left: 15px;
}
.insertHeader{

}
.insertHeader h1{
    color: #606060;
    font-size: 20px;
}
.mt{
    margin-top: 10px;
}
.widthInsert{
    width: 80%;
}

.addContainerInsert{
    display: flex;
    justify-content: flex-end;
}
.buttonInsert{
    width: 100px;
    height: 40px;
    background-color: #328cc6;
    border-radius: 8px;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}
.specialInput{
    height: 30px;
    margin-left: 10px;
}
.datesContainerInsert{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.dateContainerInsert{
    display: flex;
    flex-direction: column;
}
.insertWidth{
    width: 220px!important;
}