.alertCapacidadContainer p{
    font-size: 13px;
    font-weight: 300;
    color: #fff;
}

.alertCapacidadContainer img{
    height: 100px;
    filter: invert(50%) sepia(0%) saturate(634%) hue-rotate(133deg) brightness(164%) contrast(100%);
}

.alertCapacidadContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.alertCapacidadContainer button{
    height: 35px!important;
    width: 150px;
    font-size: 13px;
    margin-top: 20px;
}

.mt-20{
    margin-top: 20px!important;
}