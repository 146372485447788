.btnsContainer{
    display: flex;
    justify-content: flex-start;
}

.btnsContainer button{
    margin: 10px;
    width: 45px;
    height: 40px;
    color: #fff;
    background: #0071B9;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}

.miniInput{
    border: none;
    width: 50px;
   border-bottom: 1px solid #2B5373;
   background: #C0C8CF; 

}

.backgroundVictimasIndirectas{
    border-left: #2B5373 4px solid;
    background-color: #E9E9E9;
    flex-wrap: wrap;
}

.radiosContainerVictimas{
    display: flex;
}

.mt-20{
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 0;
}