.modalContainer{
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
}
.addBtn{
    width: 100%;
}
.label{
    font-weight: 700;
    margin-top: 5px;
}
.subLabel{
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 5px 0;
}

.unico{
    font-size: 16px;
    margin: 5px 0 15px 0;
}
.unicoContainer{
    margin: 5px 0 5px 0;
    display: flex;
    align-items: center;
}
.checkBox{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-left: 15px;
}

.cellCustomWidth{
    width: 30px!important;
}

.WhiteBtnYesOrNo{
    background: #fff;
    border: none;
    height: 40px;
    width: 40px;
    color: #0D78BC;
    border-radius: 7px;
    margin: 5px;
    cursor: pointer;
}

.mt-20{
    margin-top: 20px!important;
}