.infoInput{
    margin-top: 5px;
}

.selectInput{
    width: 255px;
    height: 40px;
    border-radius: 8px;
    margin-top: 5px;
    border: solid 1px #0D1F3D;
}

.userInfoContainer{
    width: 100%;
    margin: 0 5%;
}
.titleHeaderUser h1{
    color: #18243d;
    width: 100%;
    font-weight: 600;
}

.cardUserInfo{
    margin-top: 20px;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #e5e5e5;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}
.cardUserInfoLeft{
    margin-top: 20px;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
}
.inputLabelContainer{
    display: flex;
    flex-direction: column;
}
.radiosContainer{
    display: flex;
    height: 40px;
}

.radioContainer{
    display: flex;
    align-items: center;
}
.widthUser{
    width: 200px!important;
}
.buttonIngreso{
    height: 40px;
    width: 250px;
    border: none;
    border-radius: 8px;
    background-color: #2d88c1;
    color: #fff;
    margin-top: 5px;
    cursor: pointer;
}
.grid{
    margin-left: 7%;
}
.muerteAlert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
}
.sufrioTExt{
    margin: 0 40px 20px 40px;
}

.usuarioSpace{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 10%;
}


.mt-20{
    margin-top: 20px!important;
}