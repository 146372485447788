.stepsContainer{
    display: flex;
    flex-direction: column;
}

.stepsContainer h1{
    color: #606060;
    font-size: 20px;
    font-weight: 600;
}
.containerSelectIngresosModal{
    position: absolute;
    background-color: #0d78bc;
    border-radius: 7px;
    width: 80vw;
}
.selectIngresosContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.tableSelect{
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-bottom: 40px;
}
.editSelectBtn{
    border: none;
    background-color: #0d78bc;
    height: 35px;
    width: 120px;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    border-radius: 7px;
    cursor: pointer;
}

.yesOrno{
    display: flex!important;
}
.yesOrnoBTN{
    height: 40px;
    width: 45px;
    border-radius: 7px;
    background-color: #0d78bc;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    font-weight: 300;
    margin-top: 20px!important;
}

.align-center{
    display: flex;
    align-items: center;
}

.margin-top-20{
    margin-top: 20px;
}
.margin-top-10{
    margin-top: 20px;
}

.radios{
    display: flex;
    align-items: center;
    border-left: #0E4D87 2px solid;
    background: #E9E9E9;
    height: 40px;
    padding-right: 10px;
    margin-left: 10px;
}

.continuarImpactoVida{
    height: 40px;
    width: 150px;
    border: none;
    border-radius: 7px;
    background-color: #0d78bc;
    font-size: 13px;font-weight: 300;
    color: white;
    position: absolute;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
}
.mt-20{
    margin-top: 20px;
}

.column{
    display: flex;
    flex-direction: column;
}

.mt-20{
    margin-top: 20px!important;
}