.alertContainer{
    /* width: 700px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AlertHeaderContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.AlertHeaderContainer img{
    height: 100px;
}
.AlertHeaderContainer h1{
    color: #686868;
    font-size: 24px;

}
.AlertText{
    font-size: 14px;
    font-weight: 300;
    color: #585858; 
    width: 90%;
    text-align: center;   
}
.alertSubText{
    font-size: 12px;
    color: #585858; 
    text-align: center;
    font-weight: 700;
}

.AlertSubTitle{
    margin-top: 20px;
    height: 40px;
    width: 80%;
    background-color: #E14D6C;
    border-radius: 7px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
}
.alertLink{
    margin-left: 5px;
    color: #fff;
}

.alertContinueButton{
    width: 200px!important;
    margin: 20px 0 20px 0!important;
    color: #fff!important;
    background-color: #00619f!important;

}
