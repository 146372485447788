.politicsText{
    color: #606060;
    font-style: italic;
    font-size: 16px;
}

.linkRegisterPolitics{
    text-decoration: underline;
    cursor: pointer;
}

.registerPolitics{
    margin-top: 20px;
    margin-left: 10%;
}