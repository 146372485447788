

.resetContainer{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetContainerForm{
    background: #EBF2F3;
    border-radius: 7px;
    height: 300px;
    width: 500px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.sendButton{
    height: 40px;
    width: 100px;
    background: #2F8AC4;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.emailReset{
    width: 90%;
    border: 0.3px solid #20628b;
    border-radius: 7px;
    height: 40px;
    margin-bottom: 25px;
    text-indent: 10px;
}

.resetPasswordTitle{
    font-size: 26px;
    font-weight: 600;
    color: #17233C;
    margin-bottom: 20px;
}

.subTextResetPassword{
    font-size: 14px;
    font-weight: 400;
    color: gray;
    margin-bottom: 20px;
    width: 90%;
}