.preguntaContainer{
    background-color: #6B9DB3;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    border-radius: 8px;
    padding: 40px;
}
.textTableHead{
    color: #fff!important;
}
.tablePregunta{
    margin-top: 40px!important;
}

.titlePregunta{
    font-weight: 300;
}
.titlePregunta b{
    font-weight: 700;
}

.preguntaHeader{
    display: flex;
    align-items: center;
}

.buttonsContainer{
    margin-left: 20px;
}

.buttonsContainer button {
    height: 35px;
    width: 40px;
    background: #fff;
    color: #6B9DB3;
    border: none;
    border-radius: 7px;
    margin-right: 10px;
    font-weight: 300;
    cursor: pointer;
}

.alertPreguntaContainer{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alertPreguntaContainer h1{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
}

.mt-20{
    margin-top: 20px!important;
}