.text{
    color: black;
}
.containerPoliticas{
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textContainer{
    background-color: #eaebec;
    width: 50%;
    border-radius: 7px;
    padding: 30px 60px 30px 60px;
}

.textContainer h1{
    color: #606060;
    font-size: 22px;
    text-align: center;
}
.textContainer p{
    color: #606060;
    font-size: 14px;
    text-align: justify;
}