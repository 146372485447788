.container{
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 0;
}
.link{
    width: 100%;
    text-align: center;
}
.card{
    width: 30%;
    height: auto;
    margin-top: 30px;
    padding: 10px;
    background: #fff;
    color: #0D1F3D;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.card p{
    width: 80%;
    text-align: center;
    color: #585858;
}
/* .dlaLogo{
    background-color: #fff;
    border-radius: 10px;
} */

.dlaLogo{
    /* margin-top: 100px; */
}
.dlaLogo img{
    height: 150px;
}

.link{
    font-size: 12px;
    text-decoration: none;
    color: #0e1f3d;
    margin-left: 10px;
}

.checkContainer{
    display: flex;
    align-items: center;
}

.checkbox{
    margin: 0;
}

.welcomeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
h1{
    font-weight: 600;
    font-size: 25px;
}

.welcomeContainer p{
    width: 50%;
    text-align: center;
    font-weight: 300;
    color: #ffffff;
    font-size: 14px;
}

.button-local{
    width: 250px;
    color: #616161!important;
    font-weight: 300!important;
    margin-top: 40px!important;
    border-radius: 7px!important;
}

.mt-20{
    margin-top: 20px!important;
}