.tablePregunta{
    width: 80%!important;
    margin: auto!important;
    margin-bottom: 40px!important;
}

.continueBtnContainer{
    width: 80%!important;
    margin: auto;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
}

.mt-20{
    margin-top: 20px!important;
}