.erroDateContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.erroDateContainer img{
    height: 100px;
}
.erroDateContainer h1{
    color: #606060;
    font-size: 24px;
    font-weight: 600;
}

.erroDateContainer p{
    color: #606060;
    text-align: center;
    margin: 20px 50px 20px 50px;
}