.reclamanteContainer{
    width: 100%;
    margin: 0 5%;
}

.cardContainerReclamante{
    width: 100%;
    display: flex;
    background-color: #E5E5E5;
    border-radius: 7px;
    /* justify-content: center;
    margin-top: 5%; */
}
.titleContainer{
    margin-left: 30px;
}
.subContainerReclamante{
    margin: 5% 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.space-betwen{
    display: flex;
    justify-content: space-between;
}
.mr-reclamante{
    margin: 0 5%;
}
.ml-reclamante{
    margin-left: 10px;
}

.mt-reclamante{
    margin-top: 20px;
}

.p-reclamante{
    color: gray;
    font-weight: 300;
    font-size: 13px;
    width: 90%;
}

.align-center{
    align-items: center;
}

.table{
    background-color: #2A5272;
    border-radius: 7px;
    padding: 5%;
    margin-bottom: 20px;
}

.añadirVictima{
    width: 100%;
    margin-top: 20px;

}
.añadirVictimaBtn{
    border: none;
    background-color: #338DC7;
    border-radius: 7px;
    height: 35px;
    width: auto;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px 0 10px;
    position: absolute;
    right: 10%;
}
.modalHeader h2{
    color: #656565;
    font-weight: 600;
    font-size: 20px;
}

.mt-modalInsert{
    margin-top: 20px;
}

.dateContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.widthParcial{
    width: 200px;
}
.nextBTN{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.nextBTN button{
    border: none;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 500;
    background: #328DC6;
    height: 35px;
    color: #fff;
    padding: 0 10px 0 10px;
    cursor: pointer;
}

.labelModalReclamante{
    margin-left: 10px;
}
.noAplicaContainer{
    margin-top: 20px;
    border-left: #2A5272 2px solid;
    width: 100px;
    height: 35px;
    padding: 0 5px 0 5px;
    background: #E9E9E9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mt-20{
    margin-top: 20px!important;
}