body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background: #fff;
}
*{
  font-family: 'Montserrat', sans-serif!important;
}

code {
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.App{
  width: 100%;
  height: 100vh;
  background: #fff;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0D1F3D;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #0D1F3D;
  --rdp-background-color-dark: #0D1F3D;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;  
  padding: 0 5% 0 5%;
  background: linear-gradient(to bottom, #162845, #0a5ea4);
  height: 150px;
  position: sticky;
  top: 0;
  z-index: 100;
}
.nav{
  margin-left: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.btn-primary{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #10192C;
  color: #f1f1f1ef;
  border: none;
  border-radius: 25px;
  width: 110px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding-right: 10px;
}
.backIcon{
  margin-right: 10px;
  /* margin-left: 10px; */
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  background-color: #090E19;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.step{
  width: 100%;
  display: flex;
  align-items: center;
}
.step button{
  height: 30px;
  width: 30px;
  padding: 0;
  border-radius: 50%;
  border: none;
  margin: 0 5px 0 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  /* color: #558EA8;
  background: #C5D6EF; */
}
.connector{
  width: 60%;
  margin: auto;
  height: 2px;
  color: transparent;
}
.titleHeader{
  text-decoration: none;
  color: #fff;
}

.layout{
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px 10% 0 10%; */
}

.cardStep{
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  height: auto;
}
.cardStepBody{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.titleStepContainer{
  width: 52vw;
}
.titleStepContainer h1{
  font-size: 20px;
  font-weight: 700;
  color: #0D1F3D;
  margin: 0;
}
.titleStepContainer p{
  font-size: 18px;
  font-weight: 400;
  color: #585858;
  margin: 0 0 10px 0;
}

.button{
  height: 35px!important;
  width: 80%!important;
  margin: 20px 0 20px 0!important;
  font-size: 20px;
  background-color: #0D1F3D;
  border-radius: 8px!important;
  color: white!important;
  cursor: pointer!important;
  border: none!important;
}

.continueBtn{
  width: 150px;
  height: 40px;
  background-color: #338dc7;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}
.BackBtn{
  width: 100px;
  height: 40px;
  margin-right: 10px;
}

.input{
  width: 250px;
  height: 40px;
  border-radius: 7px;
  border: none;
  text-indent: 5px;
  background-color: #2a5272;
  color: #fff;
}

.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px!important;
}

.labelOption{
  color: #444444;
  font-size: 13px;
  width: auto!important;
  font-weight: 300;
}

.labelOptionBold{
  margin-top: 20px;
  color: #656565;
  font-weight: 600;
  font-size: 14px;
}

.bold{
  font-weight: 700;
  font-size: 18px;
}
.dateSelect{
  margin-top: 5px;
  width: 250px;
  height: 40px;
  border-radius: 7px;
  text-indent: 5px;
  background-color: #2a5272;
  color: #fff;
  border: none;
  text-transform: uppercase;
}
.dateSelect::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invertir el color del icono a blanco */
  transform: translateX(-10px); /* Mover 10px hacia la izquierda */
}
.logoContainer{
  /* background-color: #fff; */
  border-radius: 20px;
  margin: 5px 0 5px 0;
  margin-top: 50px;
}
.logoContainer img{
  width: 200px;
  cursor: pointer;
}
.row{
  display: flex;
}

.radioInput{
  appearance: none;
  height: 15px;
  width: 15px;
  background: #CACACA; /* Cambia esto al color que desees */
  border-radius: 50%;
  /* Establece un color de borde personalizado */
  border: 1px #325775 solid; /* Cambia esto al color que desees */
  /* Define el color del marcador (punto en el medio del botón) */
  /*outline-color: #ffffff; /* Cambia esto al color que desees */
}

/* Establece el color de fondo del botón de radio cuando está marcado */
.radioInput:checked {
  background: #637a8f; /* Cambia esto al color que desees */
  outline-color: #ffffff; /* Cambia esto al color que desees */
}

.tableHead{
  background-color: #204D72;
  height: 40px!important;
}

.textTableHead{
  padding: 0!important;
  text-align: center!important;
  width: 200px!important;
}

.tableCellBody{
  align-items: center!important;
  height: 25px!important;
  background: #fff!important;
  color: #606060!important;
  font-size: 13px!important;
  padding: 0!important;
  text-align: center!important;
  width: 200px!important;
}
.deleteContainerTable{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.blue1{
  background-color: #4B657F!important;
}
.blue2{
  background-color: #45576D!important;
}
.blue3{
  background-color: #204D72!important;
}
.blue4{
  background-color: #213D4F!important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* Puedes agregar reglas adicionales para otros navegadores, si es necesario */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.blueBTN{
  background-color: #19476c;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  min-width: 150px;
  height: 40px;
}

.alertIcon{
  height: 100px;
}

.navBtnsContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-content{
  border-radius: 7px!important;
}

.flex{
  display: flex;
}
.ml-20{
  margin-left: 20px;
}
.fullWidth{
  width: 100%;
}

.LoginRegisterLayout{
  display: flex;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.personIconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #0D1F3D;
  cursor: pointer;
}



.messageContainer{
  position: absolute;
  right: 6%;
  top: 100px;
  width: 150px;
  padding: 10px;
  border-radius: 7px;
  border-top-right-radius: 0px;
  background: #E4EEEF;
  box-shadow: #090E19 2px 3px 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.messageContainer p{
  font-size: 12px;
  font-weight: 500;
  color: #656565;
  text-align: center;
}

.messageContainer button{
  border: none;
  background: #2D8AC3;
  border-radius: 7px;
  font-size: 400;
  color: #fff;
  font-size: 12px;
  height: 25px;
  cursor: pointer;
}

.loginButtonReset{
  height: 40px;
  width: 150px;
  color: #0463A2;
  background: #E7E9ED;
  font-size: 14px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}