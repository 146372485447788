.initialWelcomeContainer{
    background: linear-gradient(to bottom, #162845, #0a5ea4);
    height: 100%;
    width: 50%;
    justify-content: left;
    align-items: center;
}

.initialWelcomeTextContainer{
    display: flex;
    flex-direction: column;
}

.InitialWelcomelogoContainer img{
    margin: 30% 0 0 10%;
    width: 300px;
}

.initialWelcomeTextContainer h1 {
    margin: 0 0 0 10%;
    font-size: 25px;
    font-weight: 600;
}
.initialWelcomeTextContainer p {
    margin: 20px 0 0 10%;
    font-size: 14px;
    font-weight: 400;
    width: 75%;
}

.InitialWelcomeseparator{
    color: transparent;
    height: 2px;
    width: 25px;
    background: #fff;
    margin: 20px 0 25px 10%;
}