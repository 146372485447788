.LoginFormContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

.loginForm{
    background: #EBF2F3;
    border-radius: 7px;
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
}

.loginFormTitle{
    color: #455064;
    font-size: 24px;
    font-weight: 600;
    margin: 10% 0 0 10%;
}

.loginFormMargin{
    margin: 3% 0 0 10%;
}

.loginFormInput{
    width: 78%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #455064;
    background: #fff;
    text-indent: 10px;
}

.loginFormButtonContainer{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginFormButton{
    height: 40px;
    width: 200px;
    background: #436783;
    border: none;
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.loginFormLink{
    font-size: 12px;
    text-decoration: none;
    color: #2F8AC4;
    margin-top: 10px;
}
.loginFormButtonCreate{
    margin: auto;
    margin-top: 30px;
    height: 40px;
    width: 160px;
    background: #2F8AC4;
    border: none;
    border-radius: 7px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}